export default {
    state: {
        menu: [
            { product: true, title: 'Flux Management', bgcolor: 'primary' },

            {
                title: 'Project', icon: 'mdi mdi-format-list-group', href: '/flux/project',
            },
            { submenu: true, variable: 'tabs'},
            { header: true, title: 'Manage' },
            {
                title: 'Chart of Accounts',
                icon: 'mdi mdi-sitemap',
                href: '/flux/manage/chartofaccount',
            }        
        ],
        tabs: [
            { header: true, title: 'This Project' },
            {
                title: 'Analysis ', icon: 'mdi-vector-difference', href: '/flux/analysis/$$id$$',
            },
            {
                title: 'Configuration', icon: 'mdi-tune', href: '/flux/configuration/$$id$$',
            },
            {
                title: 'Users', icon: 'mdi-account-group', href: '/flux/users/$$id$$',
            },

        ],   
    },
    mutations: {
    },
    actions: {
    },
    getters: {

    },
}